<template>
    <div>
        <NavBottom />
        <h1>This is an Search page</h1>
        <p>关键字: {{keyword}}</p>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                keyword: ''
            }
        },
        mounted() {
            this.keyword = this.$route.params.keyword
        }
    }
</script>

<style scoped>

</style>